import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { downloadCSV } from '@helpers/downloadCSV';
import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';

import { routes } from '@routes';

import { CustomTable } from '@components/Table';
import { Header } from '@components/Header';
import { StatusText } from '@components/Table/StatusText';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { Popover } from '@components/Popover';
import { formatDateMsDsYHI } from '@helpers/formatDateMsDsYHI';
import { HistoryIcon } from '@components/icons/History';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import { Pencil as PencilIcon } from '@components/icons/Pencil';
import { Tooltip } from '@components/Tooltip';
import { Move as MoveIcon } from '@components/icons/Move';
import { ASOne as ASOneIcon } from '@components/icons/ASOne';
import { canShowHamburger } from '@pages/User/RepositoryPage/components/JobsTable/helpers/canShowHamburger';
import { Hamburger as HamburgerIcon } from '@components/icons/Hamburger';
import { HamburgerButtons } from '@pages/User/RepositoryPage/components/JobsTable/HamburgerButtons';
import { IconButton } from '@components/form-elements/buttons/IconButton';
import { SortableHead } from '@components/Table/SortableHead';
import { SortingDirection } from '@components/Table/SortingDirection';

import { Filter } from './Filter';

import { TJob } from 'app/types/entities/TJob';
import { TJobsFilter } from '@components/todo/ReportFilter/types/TJobsFilter';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';
import { LockedJob } from '@components/icons/LockedJob';
import { Button } from '@components/form-elements/buttons/Button';
import { jobIsScheduled } from '@helpers/jobStatus/jobIsScheduled';
import { jobCanBeReassigned } from '@helpers/jobStatus/jobCanBeReassigned';
import { PagePagination } from '@components/Pagination';
import { OrganizationsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { WSUpdateReceiver } from '@pages/User/JobsListPage/components/Content/components/WSUpdateReceiver';

const Content = styled.main`
  padding: 8px 30px 70px;
  min-height: calc(100vh - 120px);

  .tag {
    padding: 4px 6px;
    border-radius: 5px;
    border: 1px solid #d5def2;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    color: #40608f;
    background: #d5def2;
  }
`;

const StyledMoveIconButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;

  :hover {
    background: #cadcf8;

    path {
      stroke: #fff;
      color: #fff;
    }
  }
`;

const StyledIconLink = styled.a`
  width: 30px;
  height: 30px;
  align-self: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  flex: 0 0 auto;

  :hover {
    background: #cadcf8;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;

const StyledHamburgerIcon = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;

  :hover {
    background: #cadcf8;

    circle {
      fill: #fff;
      color: #fff;
    }
  }
`;

const JobName = styled.div`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin: 10px 0 0;

  a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #00122d;
  }
`;

const IdField = styled.p`
  margin: 0 0 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 160%;
  color: #b4b9d9;
`;

const AssignCell = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: visible !important;

  svg {
    cursor: pointer;

    :hover {
      path {
        fill: #858dbd;
      }
    }
  }

  .assignee {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 140px;

    .assignName {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      margin: 10px 0 0;
      text-decoration: underline;
    }

    .assignEmail {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 160%;
      align-items: center;
      justify-content: center;
      margin: 0 0 10px;
      text-decoration: none;
    }
  }
`;

const DeadlineCell = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: visible !important;

  .deadLine {
    width: 95px;
  }

  svg {
    cursor: pointer;

    :hover {
      path {
        fill: #858dbd;
      }
    }
  }
`;

const StatusCell = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: 80px;

  &.with-history {
    padding-right: 28px;
  }
`;

const StatusCellContent = styled.div`
  position: relative;

  p:first-child {
    font-size: 16px;
    line-height: 130%;
    margin: 10px 0 0;
  }
`;

const StatusDate = styled.p`
  color: #b4b9d9;
  margin: 0 0 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 160%;
`;

const HistoryButton = styled.div`
  margin: 0;
  width: 18px;
  height: 18px;
  cursor: pointer;

  :hover {
    path {
      fill: #858dbd;
    }
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  gap: 10px;
  position: relative;
  overflow: visible !important;
`;

const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;
const AssignLink = styled(StyledLink)`
  text-decoration: none;
`;

export const AllJobsPage = () => {
  const { call } = useAPI();
  const { organization, me } = useAuthContext();
  const { openModal, closeModal } = useModalContext();
  const [searchParams] = useSearchParams();
  const queryPage = searchParams.get('page');
  const page = queryPage ? Number(queryPage) : 1;
  const [jobs, setJobs] = useState<TPaginatedResponse<TJob>>({
    data: [],
    count: 0,
  });
  const [reloadPageTrigger, setReloadPageTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [hiddenButtonsVisible, setHiddenButtonsVisible] = useState<string | null>(null);
  const [jobsFilter, setJobsFilter] = useState<TJobsFilter>({
    from: undefined,
    to: undefined,
  });
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [sortingField, setSortingField] = useState('createdAt');
  const [sortingReverse, setSortingReverse] = useState(true);

  const reload = () => {
    setReloadPageTrigger(!reloadPageTrigger);
  };

  const isVsAdmin = me?.roles?.ROLE_VS_ADMIN;

  const canManageWorkspaces = organization?.permissions?.manageWorkspaces;
  const canSubmitJobs = organization?.permissions?.submitJobs;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const editDeadline = (job: TJob) => {
    openModal(ModalNamesEnum.EditDeadline, {
      onSuccess: () => {
        setReloadPageTrigger(!reloadPageTrigger);
        closeModal();
      },
      job,
    });
  };

  const editAssignee = (job: TJob) => {
    openModal(ModalNamesEnum.AssignJobModal, {
      onSuccess: () => {
        setReloadPageTrigger(!reloadPageTrigger);
        closeModal();
      },
      job,
    });
  };

  const showMoveJobToWSModal = (job: TJob) => {
    openModal(ModalNamesEnum.MoveJobToWSModal, {
      onSuccess: () => {
        setReloadPageTrigger(!reloadPageTrigger);
        closeModal();
      },
      job,
    });
  };

  const handleShowHistory = (job: TJob) => {
    openModal(ModalNamesEnum.JobHistory, {
      jobId: job.id,
      workspaceId: job.workspaceId,
    });
  };

  const getPaginatedJobs = async () => {
    if (!organization?.id) {
      return;
    }
    setIsLoading(true);
    const statuses = [...(statusFilter || [])].filter((v) => v.length);
    const fromParam = Math.floor(Number(jobsFilter.from ?? null) / 1000) || '';
    const toParam = Math.floor(Number(jobsFilter.to ?? null) / 1000) || '';
    const jobs = await call(
      OrganizationsService.getAllOrganizationJobs({
        oid: organization?.id ?? '',
        statuses: statuses.join(','),
        pageNo: page.toString(),
        pageSize: pageSize.toString(),
        orderBy: sortingField,
        orderType: sortingReverse ? 'desc' : 'asc',
        search: '',
        jobsFrom: fromParam.toString(),
        jobsTo: toParam.toString(),
      }),
    );
    setJobs(jobs);
    setIsLoading(false);
  };

  useEffect(() => {
    getPaginatedJobs();
  }, [page, pageSize, organization, jobsFilter.from, jobsFilter.to, statusFilter, '', sortingField, sortingReverse]);

  const onExportCsv = async () => {
    const statuses = [...(statusFilter || [])].filter((v) => v.length);
    const fromParam = Math.floor(Number(jobsFilter.from ?? null) / 1000) || '';
    const toParam = Math.floor(Number(jobsFilter.to ?? null) / 1000) || '';
    const jobsForExport = await call(
      OrganizationsService.getAllOrganizationJobs({
        oid: organization?.id ?? '',
        statuses: statuses.join(','),
        pageNo: '1',
        pageSize: jobs.count.toString(),
        orderBy: sortingField,
        orderType: sortingReverse ? 'desc' : 'asc',
        search: '',
        jobsFrom: fromParam.toString(),
        jobsTo: toParam.toString(),
      }),
    );

    const headers = [
      'ID',
      'Title',
      'Due',
      'Assignee',
      'Organization',
      'Workspace',
      'Status',
      'Created',
      'Asr used',
      'Edits %',
    ];
    const array = jobsForExport.data.map((data: TJob) => {
      return {
        id: data.id,
        title: data.name,
        due: data.deadline ? getAmericanTime(data.deadline) : '',
        assignee: data.assignee ? `${data.assignee?.name} ${data.assignee?.lastname} (${data.assignee?.email})` : '',
        organization: organization?.name ?? '',
        workspace: data.workspaceName ?? '',
        status: JOB_STATUSES_ENUM[data.status as keyof typeof JOB_STATUSES_ENUM],
        created: getAmericanTime(data.createdAt),
        asr: data.duration,
        editsPercent: Math.round(data.editingProgress || 0),
      };
    });
    return downloadCSV(array, headers);
  };

  const toggleSort = (field: string) => {
    setSortingField(field);
    setSortingReverse(sortingField !== field ? false : !sortingReverse);
  };

  const td = [
    {
      headComponent: () => (
        <SortableHead style={{ width: '20%' }} onClick={() => toggleSort('name')}>
          Job Title
          {sortingField === 'name' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TJob) => (
        <div>
          <div className="flex gap-3 items-center">
            {data.asOneLocked ? <LockedJob className="flex-shrink-0 pl-2" width="30" /> : null}
            <div>
              <JobName data-testid={`jobTitle${data.name}`}>
                {data.asOneLocked ? (
                  data.name
                ) : (
                  <div className="flex flex-row">
                    {data.meetingId ? <div className="tag mr-2"> AS ONE </div> : null}
                    <StyledLink to={routes.jobDetails.make(organization?.slug ?? '', data.workspaceSlug, data.id)}>
                      {data.name}
                    </StyledLink>
                  </div>
                )}
              </JobName>
              <IdField>{data.id}</IdField>
            </div>
          </div>
        </div>
      ),
    },
    {
      render: (data: TJob) => (
        <DeadlineCell>
          {!data.deadline && <p className="deadLine"></p>}
          {data.deadline && <p className="deadLine">{getAmericanTime(data.deadline, false)}</p>}
          {organization?.permissions?.submitJobs && <PencilIcon onClick={() => editDeadline(data)} />}
        </DeadlineCell>
      ),
      headComponent: () => (
        <SortableHead style={{ width: '20%' }} onClick={() => toggleSort('deadline')}>
          Due
          {sortingField === 'deadline' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
    },
    {
      render: (data: TJob, index: number) => {
        const showEditAssigneeButton = organization?.permissions?.reassignJobs && jobCanBeReassigned(data.status);
        return (
          <AssignCell>
            {!data?.assignee ? <div className="assignee"></div> : null}
            {data?.assignee ? (
              <Tooltip key={index} content={data?.assignee?.email || ''} placement="bottom">
                <div className="assignee">
                  <AssignLink to={routes.userDetails.make(organization?.slug ?? '', data?.assignee?.id)}>
                    <p className="assignName">
                      {data?.assignee?.name} {data?.assignee?.lastname}
                    </p>
                    <p className="assignEmail">{data?.assignee?.email || ''}</p>
                  </AssignLink>
                </div>
              </Tooltip>
            ) : null}
            {showEditAssigneeButton && <PencilIcon onClick={() => editAssignee(data)} />}
          </AssignCell>
        );
      },
      headComponent: () => (
        <SortableHead style={{ width: '20%' }} onClick={() => toggleSort('assigneeEmail')}>
          ASSIGNEE
          {sortingField === 'assigneeEmail' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
    },
    {
      render: (data: TJob) => <p>{getAmericanTime(data.createdAt)}</p>,
      headComponent: () => (
        <SortableHead style={{ width: '20%' }} onClick={() => toggleSort('createdAt')}>
          Created
          {sortingField === 'createdAt' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
    },
    {
      render: (data: TJob) => {
        return (
          <StatusCell className="with-history">
            <StatusCellContent>
              {data.asOneLocked && data.status === 'JOB_STATUS_PROCESSING' ? (
                <StatusText
                  testid={`jobStatus${data.name}`}
                  status={data.pipelineStatus === 'assembly_submitter_completed' ? 'Locked' : 'Preprocessing'}
                />
              ) : (
                <StatusText testid={`jobStatus${data.name}`} status={JOB_STATUSES_ENUM[data.status]} />
              )}
              <StatusDate>{formatDateMsDsYHI(data.statusChangeDate || '')}</StatusDate>
            </StatusCellContent>
            <HistoryButton onClick={() => handleShowHistory(data)}>
              <HistoryIcon />
            </HistoryButton>
          </StatusCell>
        );
      },
      headComponent: () => (
        <SortableHead style={{ width: '20%' }} onClick={() => toggleSort('status')}>
          Status
          {sortingField === 'status' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
    },
    {
      render: (data: TJob) => <p>{data.workspaceName}</p>,
      headComponent: () => (
        <SortableHead style={{ width: '20%' }} onClick={() => toggleSort('workspaceName')}>
          Workspace
          {sortingField === 'workspaceName' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
    },

    {
      render: (data: TJob) => {
        const showASOneButton = !data.asOneLocked && jobIsScheduled(data.status);

        const getASOneLinkPrefix = () => {
          return `${window.location.origin}/as-one/job/`;
        };

        const unlockJob = (id: string, name: string, partialUnlocked: boolean) => {
          openModal(ModalNamesEnum.UnlockJob, {
            onCancel: closeModal,
            onSuccess: () => {
              closeModal();
              reload();
            },
            isSubmitting: false,
            id,
            name,
            partialUnlocked,
          });
        };

        return (
          <IconsWrapper>
            {data.asOneLocked && data.pipelineStatus === 'assembly_submitter_completed' ? (
              <Button
                className="unlock-button"
                onClick={() => unlockJob(data.id, data.name, data.partialUnlocked)}
                variant="outlined"
              >
                Unlock now
              </Button>
            ) : null}
            {!data.asOneLocked && canSubmitJobs ? (
              <StyledMoveIconButton title="Move to another Workspace" onClick={() => showMoveJobToWSModal(data)}>
                <MoveIcon />
              </StyledMoveIconButton>
            ) : null}
            {showASOneButton ? (
              <StyledIconLink
                title="Open in AutoScriptOne"
                href={`${getASOneLinkPrefix()}${data.meetingId}`}
                aria-disabled={false}
              >
                <ASOneIcon />
              </StyledIconLink>
            ) : null}
            {!data.asOneLocked && canShowHamburger(data, isVsAdmin, canManageWorkspaces, organization, me) ? (
              <>
                <StyledHamburgerIcon
                  title="Open additional actions"
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.preventDefault();
                    setHiddenButtonsVisible(data.id);
                  }}
                >
                  <HamburgerIcon />
                </StyledHamburgerIcon>

                {hiddenButtonsVisible === data.id ? (
                  <HamburgerButtons
                    job={data}
                    reloadPageTrigger={reloadPageTrigger}
                    setReloadPageTrigger={setReloadPageTrigger}
                    setHiddenButtonsVisible={setHiddenButtonsVisible}
                  />
                ) : null}
              </>
            ) : null}
          </IconsWrapper>
        );
      },
      renderStyles: { width: '1%' },
      headComponent: () => <td style={{ width: '1%' }} />,
    },
  ];

  const FilterPopover = () => {
    return (
      <Popover
        styles="margin-top: 40px; margin-left: -100px;"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Filter
          jobsFilter={jobsFilter}
          jobsFilterOnChange={(e) => setJobsFilter(e)}
          statusFilter={statusFilter}
          statusFilterOnChange={(e) => setStatusFilter(e)}
          handleSubmit={reload}
          handleClose={() => setAnchorEl(null)}
        />
      </Popover>
    );
  };

  return (
    <>
      <Helmet>
        <title>All Jobs - AutoScript</title>
      </Helmet>
      <Header
        title="All Jobs"
        globalSearch={true}
        buttonLabel={jobs?.data?.length ? 'Export CSV' : undefined}
        buttonOnClick={jobs?.data?.length ? () => onExportCsv() : undefined}
        onFilterClick={(e) => setAnchorEl(e.currentTarget)}
        filterCounter={jobsFilter.from || jobsFilter.to || statusFilter.length ? 1 : 0}
      />

      <LinearProgressLoader active={isLoading} />
      <Content>
        <CustomTable data={jobs?.data ?? []} head={td} />
        <WSUpdateReceiver jobs={jobs} reload={reload} />
        <PagePagination totalItemsCount={jobs?.count ?? 0} pageSize={pageSize} setPageSize={setPageSize} />
      </Content>

      <FilterPopover />
    </>
  );
};
