import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { BillingAccountService, PaymentService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { useAuthContext } from '@providers/AuthProvider';
import { useWebsocketContext } from '@providers/WebsocketProvider';
import { getTransactions } from '@queries/billing-account/getTransactions';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { downloadCSV } from '@helpers/downloadCSV';
import { formatCentsPrice } from '@helpers/formatCentsPrice';

import { Header } from '@components/Header';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { DiagonalArrow } from '@components/icons/DiagonalArrow';
import { CustomTable } from '@components/Table';
import { PagePagination } from '@components/Pagination';

import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { TReceipt } from 'app/types/entities/TReceipt';
import { TPaymentMethod } from 'app/types/entities/TPaymentMethod';

import { AutoTopUpCard } from './components/AutoTopUpCard/AutoTopUpCard';
import { TransactionType } from './components/TransactionType';
import { PaymentMethodCard } from './components/PaymentMethodCard';
import { CreditBalanceCard } from './components/CreditBalanceCard';
import { SubscriptionsWrapper } from './components/SubscriptionsWrapper';

export const StyledTitle = styled.h1`
  font-weight: 600;
  font-size: 26px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #40608f;
`;

const Wrapper = styled.div`
  padding: 30px 30px 100px;
  min-height: calc(100vh - 110px);
`;

const Cell = styled.div`
  .text-858dbd {
    color: #858dbd;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  grid-gap: 30px;
`;

const ReceiptUrl = styled.a`
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-decoration-line: none;
  border-bottom: 1px solid #858dbd;
  display: inline-flex;
  color: #858dbd;

  :hover {
    border-bottom: 1px solid transparent;
  }
`;

export const BillingAccountDetailsPage = () => {
  const { call } = useAPI();
  const { orgSlug } = useParams<{ orgSlug?: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const { onWSUpdate } = useWebsocketContext();
  const { me, updateMe, organization } = useAuthContext();
  const [searchParams] = useSearchParams();
  const queryPage = searchParams.get('page');
  const page = queryPage ? Number(queryPage) : 1;

  const [billingAccount, setBillingAccount] = useState<TBillingAccount>();
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const [data, setData] = useState<{ count: number; data: TReceipt[] }>({ count: 0, data: [] });
  const [pageSize, setPageSize] = useState(25);
  const [paymentMethods, setPaymentMethods] = useState<{ default: TPaymentMethod; all: TPaymentMethod[] }>();

  const billingAccountOrg = me.organizations.find(
    (org) => org.billingAccount?.id === (organization?.billingAccount?.id ?? ''),
  );
  const canManageBillingAccount = me.id === billingAccount?.ownerId || billingAccountOrg?.permissions?.manageBilling;

  const load = async () => {
    if (!organization?.billingAccount?.id) {
      return;
    }
    const billingAccountId = organization?.billingAccount?.id ?? '';
    const billingAccount = await call(BillingAccountService.findOne({ baId: billingAccountId }));
    setBillingAccount(billingAccount);
    const paymentMethods = await call(PaymentService.billingAccountPaymentMethodList({ baId: billingAccountId }));

    setPaymentMethods({
      default: paymentMethods.find((item) => item.default) || paymentMethods[0],
      all: paymentMethods,
    });
    setIsLoading(false);
  };

  const loadData = async () => {
    if (!organization?.billingAccount?.id) {
      return;
    }
    setIsLoading(true);
    const response = await getTransactions(organization?.billingAccount?.id ?? '');
    const data = response.slice(pageSize * page - pageSize, pageSize * page);
    setData({ count: response.length, data });
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [reloadTrigger, page, pageSize, organization, billingAccount, organization?.billingAccount?.id]);

  const reloadCards = () => {
    setReloadTrigger((v) => !v);
    // closeModal();
  };

  // Todo: websocket issue poll
  useEffect(() => {
    const interval = setInterval(() => {
      load();
      updateMe();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    reloadCards();
  }, [onWSUpdate]);

  useEffect(() => {
    load();
  }, [reloadTrigger, organization?.billingAccount?.id]);

  const receiptTableHead = [
    {
      render: (data: TReceipt) => <p className="my-1">{getAmericanTime(data.createdAt)}</p>,
      headComponent: () => <td style={{ width: '170px', maxWidth: '170px' }}>Created</td>,
    },
    {
      render: (data: TReceipt) => (
        <Cell>
          <p className="my-1">
            {data.title} <br />
            <small className="text-858dbd text-xs">Charge ID: {data.stripeChargeId ?? data.id}</small>
          </p>
        </Cell>
      ),
      headComponent: () => <td>Title</td>,
    },
    {
      render: (data: TReceipt) => <p className="my-1">{formatCentsPrice(data.amount)}</p>,
      headComponent: () => <td style={{ width: '90px', maxWidth: '90px' }}>Amount</td>,
    },
    {
      render: (data: TReceipt) => <TransactionType className="my-1" type={data.code.name} />,
      headComponent: () => <td style={{ width: '90px', maxWidth: '90px' }}>Type</td>,
    },
    {
      render: (data: TReceipt) => (
        <div className="my-1">
          {data.stripeReceiptUrl ? (
            <ReceiptUrl href={data.stripeReceiptUrl} target="_blank" rel="noreferrer">
              Receipt{' '}
              <div className="mt-2 ml-1">
                <DiagonalArrow />
              </div>
            </ReceiptUrl>
          ) : null}
        </div>
      ),
      headComponent: () => <td style={{ width: '85px', maxWidth: '85px' }}>Receipt Url</td>,
    },
  ];

  const onExportCsv = async () => {
    const data = await getTransactions(organization?.billingAccount?.id ?? '');
    const headers = ['ID', 'Title', 'Charge ID', 'Amount', 'Type', 'Receipt URL', 'Created'];
    const array = data.map((data: TReceipt) => {
      return {
        id: data.id,
        title: data.title,
        chargeId: data.stripeChargeId ?? data.id,
        amount: `$${(data.amount / 100).toFixed(2)}`,
        type: data.code.name,
        receiptUrl: data.stripeReceiptUrl ?? '',
        created: getAmericanTime(data.createdAt),
      };
    });
    return downloadCSV(array, headers);
  };

  if (!orgSlug || !organization) {
    return (
      <>
        <Helmet>
          <title>Billing Account Details - AutoScript</title>
        </Helmet>
        <Header title="Billing Account Details" />
        <LinearProgressLoader active={isLoading} />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Billing Account Details - AutoScript</title>
      </Helmet>
      <Header
        title="Billing Account Details"
        buttonLabel={data.data?.length ? 'Export CSV' : undefined}
        buttonOnClick={data.data?.length ? () => onExportCsv() : undefined}
      />

      <LinearProgressLoader active={isLoading} />
      <Wrapper>
        <CardWrapper>
          <PaymentMethodCard
            method={paymentMethods?.default}
            billingAccount={billingAccount}
            canManageBillingAccount={canManageBillingAccount}
            reloadCards={reloadCards}
          />
          <CreditBalanceCard
            billingAccount={billingAccount}
            canManageBillingAccount={canManageBillingAccount}
            reloadCards={reloadCards}
          />
          {billingAccount && paymentMethods ? (
            <AutoTopUpCard billingAccount={billingAccount} paymentMethods={paymentMethods} reloadData={load} />
          ) : null}
        </CardWrapper>

        <SubscriptionsWrapper
          billingAccount={billingAccount}
          reloadPage={reloadCards}
          hasPaymentMethod={!!paymentMethods?.default}
        />

        <StyledTitle className="mt-8">Transactions</StyledTitle>
        <CustomTable data={data.data} head={receiptTableHead} />
        <PagePagination totalItemsCount={data.count ?? 0} pageSize={pageSize} setPageSize={setPageSize} />
      </Wrapper>
    </>
  );
};
